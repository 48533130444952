import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import Layout from '../Layout/index';
import Home from '../Home/index';
import MainWrapper from './MainWrapper';
import LogIn from '../LogIn/index';
import LogInAluno from '../LogInAluno/index';
import Inscricao from '../Inscricao';
import Pagamentos from '../Pagamentos';
import Quiz from '../Quiz/index';
import SituacaoInscricao from '../SituacaoInscricao';
import Viewer from '../Certificado';
import SituacaoPagamento from '../SituacaoPagamento';
import Footer from '../Layout/footer/Footer';

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route
        exact
        path="/acesso/:emailAluno/:idUscreen"
        component={LogInAluno}
      />
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute exact path="/home" component={Home} />
      <PrivateRoute exact path="/inscricao/:id" component={Inscricao} />
      <PrivateRoute exact path="/quiz/:id" component={Quiz} />
      <Route exact path="/certificado/:id" component={Viewer} />
      <PrivateRoute
        exact
        path="/situacao-inscricao/:id"
        component={SituacaoInscricao}
      />
      <PrivateRoute exact path="/pagamento/:id" component={SituacaoPagamento} />
      <PrivateRoute exact path="/pagamentos" component={Pagamentos} />
    </div>
    <Footer />
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/log_in" component={LogIn} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
