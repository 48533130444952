import { handleActions } from 'redux-actions';
import {
  loginAlunoRequest,
  loginAlunoSuccess,
  loginAlunoError,
  setAlunoData,
} from '../actions/alunosActions';

const defaultState = {
  auth: {},
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [loginAlunoRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [loginAlunoSuccess](state, { payload }) {
      return {
        ...state,
        auth: payload,
        isFetching: false,
        error: null,
      };
    },
    [loginAlunoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [setAlunoData](state, { payload }) {
      return {
        ...state,
        auth: payload,
        isFetching: false,
        error: null,
      };
    },
  },
  defaultState
);
