import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ListaCertificados from './components/ListaCertificados';
import { fetchCertificadosDisponiveis } from '../../redux/actions/certificadosActions';
import {
  fetchInscricoesEmAndamento,
  fetchInscricoesConcluidas,
} from '../../redux/actions/inscricoesActions';
import { setAluno } from '../../redux/actions/alunosActions';
import ListaInscritos from './components/ListaInscritos';

const Home = () => {
  const dispatch = useDispatch();
  const certificadosDisponiveis = useSelector(
    (state) => state.certificados.certificadosDisponiveis
  );
  const inscricoesEmAndamento = useSelector(
    (state) => state.inscricoes.inscricoesEmAndamento
  );
  const inscricoesConcluidas = useSelector(
    (state) => state.inscricoes.inscricoesConcluidas
  );
  const { aluno } = useSelector((state) => state.alunos.auth);

  useEffect(() => {
    dispatch(setAluno());
    dispatch(fetchCertificadosDisponiveis());
    dispatch(fetchInscricoesEmAndamento());
    dispatch(fetchInscricoesConcluidas());
  }, [dispatch]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h1 className="page-title">Certificados</h1>
          <h3 className="page-subhead subhead">
            {`Bem vindo ${aluno?.name}, nesta área você poderá obter certificados, fazer testes para obtenção de novos certificados e emitir os certificados já obtidos. Caso tenha alguma dúvida sobre pagamentos clique no botão abaixo para verificar.`}
          </h3>
        </Col>
      </Row>
      <Row>
        <ListaCertificados
          titulo={`Inscreva-se!`}
          lista={certificadosDisponiveis}
        />
        <ListaInscritos titulo={`Faça o teste`} lista={inscricoesEmAndamento} />
        <ListaInscritos
          titulo={`Teste concluídos`}
          lista={inscricoesConcluidas}
        />
      </Row>
      <Row className="justify-content-center mt-5">
        <p>Verifique a situação de pagamentos anteriores.</p>
      </Row>
      <Row className="justify-content-center mt-2">
        <Link className="btn btn-secondary" to="/pagamentos">
          Pagamentos anteriores
        </Link>
      </Row>
    </Container>
  );
};

Home.propTypes = {};

export default connect((state) => ({
  rtl: state.rtl,
}))(Home);
