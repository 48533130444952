import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import ItemCertificado from './ItemCertificado';

const ListaCertificados = ({ titulo, lista }) => (
  <Col md={12} lg={4} xl={4} className="mb-3">
    <Card className="card--not-full-height">
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">{titulo}</h5>
        </div>
        {lista?.map((item) => (
          <ItemCertificado
            titulo={item.titulo}
            status={item.status}
            descricao={item.descricao}
            id={item._id}
            key={item._id}
          />
        ))}
        {lista.length === 0 && (
          <p className="project-member__name">Nada aqui por enquanto...</p>
        )}
      </CardBody>
    </Card>
  </Col>
);

export default ListaCertificados;
