import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import QuizStart from './components/QuizStart';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInscricao } from '../../redux/actions/inscricoesActions';
import { fetchCertificado } from '../../redux/actions/certificadosActions';

const Quiz = (props) => {
  const dispatch = useDispatch();
  const inscricaoAtual = useSelector((state) => state.inscricoes.inscricao);
  const certificado = useSelector((state) => state.certificados.certificado);
  const [permitir, setPermitir] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const { id } = props.match.params;
  const location = useLocation();
  const inscricaoId =
    location && location.state ? location.state.inscricaoId : undefined;

  useEffect(() => {
    if (
      (inscricaoAtual && inscricaoAtual.status === 'inscrito') ||
      inscricaoAtual.status === 'em execução'
    ) {
      setPermitir(true);
    }
  }, [inscricaoAtual]);

  useEffect(() => {
    dispatch(fetchInscricao(inscricaoId));
    dispatch(fetchCertificado(id));
  }, [dispatch, id, inscricaoId]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h1 className="page-title">Teste</h1>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={8}>
          <Card>
            <CardBody>
              {!permitir && !showResult ? (
                <>
                  <h4 className="bold-text">Acesso Negado</h4>
                  <p className="typography-message">
                    Você não pode mais fazer este teste!
                  </p>
                </>
              ) : (
                <QuizStart
                  inscricaoId={inscricaoId}
                  certificado={certificado}
                  inscricaoAtual={inscricaoAtual}
                  setShowResult={setShowResult}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Quiz;
