import { createAction } from 'redux-actions';
import cuponsApi from '../../utils/api/cuponsApi';

export const validarCupomRequest = createAction('VALIDAR_LOGIN_REQUEST');
export const validarCupomSuccess = createAction('VALIDAR_LOGIN_SUCCESS');
export const validarCupomError = createAction('VALIDAR_LOGIN_ERROR');

export const validarCupom = (codigo) => async (dispatch) => {
  try {
    dispatch(validarCupomRequest());
    const { data } = await cuponsApi.validarCupom(codigo);
    dispatch(validarCupomSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(validarCupomError(e.error));
  }
};
