/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.cpf) {
    errors.cpf = 'O CPF não pode ser vazio';
  }
  if (!values.cep) {
    errors.cep = 'O CEP não pode ser vazio';
  }
  if (!values.uf) {
    errors.uf = 'O campo UF não pode ser vazio';
  }
  if (!values.cidade) {
    errors.cidade = 'O campo cidade não pode ser vazio';
  }
  if (!values.bairro) {
    errors.bairro = 'O campo bairro não pode ser vazio';
  }
  if (!values.endereco) {
    errors.endereco = 'O campo endereço não pode ser vazio';
  }
  return errors;
};

export default validate;
