import { handleActions } from 'redux-actions';
import {
  validarCupomRequest,
  validarCupomSuccess,
  validarCupomError,
} from '../actions/cuponsActions';

const defaultState = {
  cupom: {},
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [validarCupomRequest](state) {
      return {
        ...state,
        cupom: {},
        isFetching: true,
        error: null,
      };
    },
    [validarCupomSuccess](state, { payload }) {
      return {
        ...state,
        cupom: payload,
        isFetching: false,
        error: null,
      };
    },
    [validarCupomError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
