import { createAction } from 'redux-actions';
import alunosApi from '../../utils/api/alunosApi';

export const loginAlunoRequest = createAction('LOGIN_ALUNO_REQUEST');
export const loginAlunoSuccess = createAction('LOGIN_ALUNO_SUCCESS');
export const loginAlunoError = createAction('LOGIN_ALUNO_ERROR');
export const setAlunoData = createAction('SET_ALUNO_DATA');

export const loginAluno = (emailAluno, idUscreen) => async (dispatch) => {
  try {
    dispatch(loginAlunoRequest());
    const { data } = await alunosApi.loginAluno(emailAluno, idUscreen);
    localStorage.setItem('certificadoNerdflixUser', JSON.stringify(data));
    dispatch(loginAlunoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(loginAlunoError(e));
  }
};

export const setAluno = () => async (dispatch) => {
  dispatch(
    setAlunoData(JSON.parse(localStorage.getItem('certificadoNerdflixUser')))
  );
};
