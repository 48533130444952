import axios from 'axios';
import { getToken } from '../../helpers';

export const defaultParams = () => ({
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  },
});

export default axios;
