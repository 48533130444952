import { get, post, put } from './base/index';
import { API, getUserId } from '../helpers';

export default {
  getInscricoesEmAndamento: () =>
    get(`${API}/inscricoes/em-andamento/${getUserId()}`),
  getInscricoesConcluidas: () =>
    get(`${API}/inscricoes/concluidas/${getUserId()}`),
  novaInscricaoCartaoCredito: (certificadoId, form) =>
    post(
      `${API}/inscricao/nova/cartaocredito/${getUserId()}/${certificadoId}`,
      form
    ),
  novaInscricaoBoletoBancario: (certificadoId, form) =>
    post(`${API}/inscricao/nova/boleto/${getUserId()}/${certificadoId}`, form),
  novaInscricaoCupom: (certificadoId, form) =>
    post(`${API}/inscricao/nova/cupom/${getUserId()}/${certificadoId}`, form),
  getInscricao: (id) => get(`${API}/inscricao/${id}`),
  sendTestResult: (id, result) =>
    put(`${API}/inscricao/resultado/${getUserId()}/${id}`, result),
  pdfCertificado: (id) => get(`${API}/inscricao/certificado/pdf/${id}`),
};
