import { createAction } from 'redux-actions';
import certificadosApi from '../../utils/api/certificadosApi';

export const fetchCertificadoRequest = createAction(
  'FETCH_CERTIFICADO_REQUEST'
);
export const fetchCertificadoSuccess = createAction(
  'FETCH_CERTIFICADO_SUCCESS'
);
export const fetchCertificadoError = createAction('FETCH_CERTIFICADO_ERROR');

export const fetchCertificadosDisponiveisRequest = createAction(
  'FETCH_CERTIFICADOS_DISPONIVEIS_REQUEST'
);
export const fetchCertificadosDisponiveisSuccess = createAction(
  'FETCH_CERTIFICADOS_DISPONIVEIS_SUCCESS'
);
export const fetchCertificadosDisponiveisError = createAction(
  'FETCH_CERTIFICADOS_DISPONIVEIS_ERROR'
);

export const fetchCertificado = (id) => async (dispatch) => {
  try {
    dispatch(fetchCertificadoRequest());
    const { data } = await certificadosApi.getCertificado(id);
    dispatch(fetchCertificadoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchCertificadoError());
  }
};

export const fetchCertificadosDisponiveis = () => async (dispatch) => {
  try {
    dispatch(fetchCertificadosDisponiveisRequest());
    const { data } = await certificadosApi.getCertificadosDisponiveis();
    dispatch(fetchCertificadosDisponiveisSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchCertificadosDisponiveisError());
  }
};
