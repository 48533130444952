import { handleActions } from 'redux-actions';
import {
  fetchCertificadoRequest,
  fetchCertificadoSuccess,
  fetchCertificadoError,
  fetchCertificadosDisponiveisRequest,
  fetchCertificadosDisponiveisSuccess,
  fetchCertificadosDisponiveisError,
} from '../actions/certificadosActions';

const defaultState = {
  certificado: {},
  certificados: [],
  certificadosDisponiveis: [],
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [fetchCertificadoRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchCertificadoSuccess](state, { payload }) {
      return {
        ...state,
        certificado: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchCertificadoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchCertificadosDisponiveisRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchCertificadosDisponiveisSuccess](state, { payload }) {
      return {
        ...state,
        certificadosDisponiveis: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchCertificadosDisponiveisError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
