import React from 'react';
import { Button, ButtonToolbar, Col, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import * as PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import CreditCardIcon from 'mdi-react/CreditCardIcon';
import validate from './validate';

const renderField = ({
  input,
  placeholder,
  type,
  mask,
  meta: { touched, error },
}) => (
  <div>
    <MaskedInput {...input} placeholder={placeholder} type={type} mask={mask} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

const renderFieldText = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);
renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  mask: PropTypes.arrayOf(PropTypes.any),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  type: 'text',
  mask: [],
  meta: null,
};

const CartaoCreditoForm = ({ handleSubmit, certificado }) => (
  <form className="form payment__credit-cards" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <input type="hidden" name="forma_pagamento" value="cartaocredito" />
      <Row>
        <Col lg={4}>
          <span className="form__form-group-label">Número do cartão</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <CreditCardIcon />
            </div>
            <Field
              name="CardNumber"
              component={renderField}
              type="text"
              mask={[
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              placeholder="xxxx-xxxx-xxxx-xxxx"
            />
          </div>
        </Col>
        <Col lg={4}>
          <div className="form__form-group form__form-group-date">
            <span className="form__form-group-label">Válido até</span>
            <div className="form__form-group-field">
              <Field
                name="ExpirationDate"
                component={renderField}
                type="text"
                mask={[/[0-1]/, /\d/, '/', /\d/, /\d/]}
                placeholder="MM/AA"
              />
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <div className="form__form-group form__form-group-cvc">
            <span className="form__form-group-label">CVV</span>
            <div className="form__form-group-field">
              <Field
                name="SecurityCode"
                component={renderField}
                type="text"
                mask={[/\d/, /\d/, /\d/]}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>

    <div className="form__form-group">
      <span className="form__form-group-label">Nome impresso no cartão</span>
      <div className="form__form-group-field">
        <Field
          name="Holder"
          component={renderFieldText}
          type="text"
          placeholder="Nome e sobrenome"
        />
      </div>
    </div>

    <h4 className="payment__total">Valor Total: R${certificado.valor}</h4>
    <ButtonToolbar className="form__button-toolbar">
      <Button color="primary" type="submit">
        Realizar Pagamento
      </Button>
    </ButtonToolbar>
  </form>
);

CartaoCreditoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'payment_form', // a unique identifier for this form
  validate,
})(CartaoCreditoForm);
