import { createAction } from 'redux-actions';
import inscricoesApi from '../../utils/api/inscricoesApi';

export const fetchInscricaoRequest = createAction('FETCH_INSCRICAO_REQUEST');
export const fetchInscricaoSuccess = createAction('FETCH_INSCRICAO_SUCCESS');
export const fetchInscricaoError = createAction('FETCH_INSCRICAO_ERROR');

export const fetchInscricoesEmAndamentoRequest = createAction(
  'FETCH_INSCRICOES_EM_ANDAMENTO_REQUEST'
);
export const fetchInscricoesEmAndamentoSuccess = createAction(
  'FETCH_INSCRICOES_EM_ANDAMENTO_SUCCESS'
);
export const fetchInscricoesEmAndamentoError = createAction(
  'FETCH_INSCRICOES_EM_ANDAMENTO_ERROR'
);

export const fetchInscricoesConcluidasRequest = createAction(
  'FETCH_INSCRICOES_CONCLUIDAS_REQUEST'
);
export const fetchInscricoesConcluidasSuccess = createAction(
  'FETCH_INSCRICOES_CONCLUIDAS_SUCCESS'
);
export const fetchInscricoesConcluidasError = createAction(
  'FETCH_INSCRICOES_CONCLUIDAS_ERROR'
);

export const novaInscricaoCartaoCreditoRequest = createAction(
  'NOVA_INSCRICAO_CARTAO_CREDITO_REQUEST'
);
export const novaInscricaoCartaoCreditoSuccess = createAction(
  'NOVA_INSCRICAO_CARTAO_CREDITO_SUCCESS'
);
export const novaInscricaoCartaoCreditoError = createAction(
  'NOVA_INSCRICAO_CARTAO_CREDITO_ERROR'
);

export const novaInscricaoBoletoBancarioRequest = createAction(
  'NOVA_INSCRICAO_BOLETO_BANCARIO_REQUEST'
);
export const novaInscricaoBoletoBancarioSuccess = createAction(
  'NOVA_INSCRICAO_BOLETO_BANCARIO_SUCCESS'
);
export const novaInscricaoBoletoBancarioError = createAction(
  'NOVA_INSCRICAO_BOLETO_BANCARIO_ERROR'
);

export const novaInscricaoCupomRequest = createAction(
  'NOVA_INSCRICAO_CUPOM_REQUEST'
);
export const novaInscricaoCupomSuccess = createAction(
  'NOVA_INSCRICAO_CUPOM_SUCCESS'
);
export const novaInscricaoCupomError = createAction(
  'NOVA_INSCRICAO_CUPOM_ERROR'
);

export const testeEnviadoSuccess = createAction('TESTE_ENVIADO_SUCCESS');
export const testeEnviadoError = createAction('TESTE_ENVIADO_ERROR');

export const fetchPdfCertificadoRequest = createAction(
  'FETCH_PDF_CERTIFICADO_REQUEST'
);
export const fetchPdfCertificadoSuccess = createAction(
  'FETCH_PDF_CERTIFICADO_SUCCESS'
);
export const fetchPdfCertificadoError = createAction(
  'FETCH_PDF_CERTIFICADO_ERROR'
);

export const fetchInscricao = (id) => async (dispatch) => {
  try {
    dispatch(fetchInscricaoRequest());
    const { data } = await inscricoesApi.getInscricao(id);
    dispatch(fetchInscricaoSuccess(data));
  } catch (e) {
    console.log(e.response);
    dispatch(fetchInscricaoError(e));
  }
};

export const fetchInscricoesEmAndamento = () => async (dispatch) => {
  try {
    dispatch(fetchInscricoesEmAndamentoRequest());
    const { data } = await inscricoesApi.getInscricoesEmAndamento();
    dispatch(fetchInscricoesEmAndamentoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchInscricoesEmAndamentoError(e));
  }
};

export const fetchInscricoesConcluidas = () => async (dispatch) => {
  try {
    dispatch(fetchInscricoesConcluidasRequest());
    const { data } = await inscricoesApi.getInscricoesConcluidas();
    dispatch(fetchInscricoesConcluidasSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchInscricoesConcluidasError(e));
  }
};

export const novaInscricaoCartaoCredito =
  (certificadoId, form) => async (dispatch) => {
    try {
      dispatch(novaInscricaoCartaoCreditoRequest());
      const { data } = await inscricoesApi.novaInscricaoCartaoCredito(
        certificadoId,
        form
      );
      dispatch(novaInscricaoCartaoCreditoSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(novaInscricaoCartaoCreditoError(e.error));
    }
  };

export const novaInscricaoBoletoBancario =
  (certificadoId, form) => async (dispatch) => {
    try {
      dispatch(novaInscricaoBoletoBancarioRequest());
      const { data } = await inscricoesApi.novaInscricaoBoletoBancario(
        certificadoId,
        form
      );
      dispatch(novaInscricaoBoletoBancarioSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(novaInscricaoBoletoBancarioError(e.error));
    }
  };

export const novaInscricaoCupom = (certificadoId, form) => async (dispatch) => {
  try {
    dispatch(novaInscricaoCupomRequest());
    const { data } = await inscricoesApi.novaInscricaoCupom(
      certificadoId,
      form
    );
    dispatch(novaInscricaoCupomSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(novaInscricaoCupomError(e.error));
  }
};

export const enviarResultadoTeste = (inscricaoId, form) => async (dispatch) => {
  try {
    const { data } = await inscricoesApi.sendTestResult(inscricaoId, form);
    dispatch(testeEnviadoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(testeEnviadoError(e));
  }
};

export const fetchPdfCertificado = (inscricaoId) => async (dispatch) => {
  try {
    dispatch(fetchPdfCertificadoRequest());
    const { data } = await inscricoesApi.pdfCertificado(inscricaoId);
    dispatch(fetchPdfCertificadoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchPdfCertificadoError(e));
  }
};
