import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import DetalheCertificado from '../Comuns/DetalheCertificado';
import PagamentoInscricao from './components/PagamentoInscricao';

const Inscricao = (props) => (
  <Container>
    <Row>
      <Col md={12}>
        <h1 className="page-title">Inscrição</h1>
        <h3 className="page-subhead subhead">
          Após selecionar a forma de pagamento e realizar sua inscrição, você
          poderá fazer o teste e emitir o seu certificado.
        </h3>
      </Col>
    </Row>
    <Row>
      <Col lg={{ span: 8, order: 1 }} md={8} xs={{ span: 12, order: 2 }}>
        <PagamentoInscricao props={props} />
      </Col>
      <Col
        lg={{ span: 4, order: 2 }}
        md={4}
        xs={{ span: 12, order: 1 }}
        className="mb-4"
      >
        <DetalheCertificado props={props} />
      </Col>
    </Row>
  </Container>
);

export default Inscricao;
