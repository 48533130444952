import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import HamburgerMenuIcon from 'mdi-react/HamburgerMenuIcon';

const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => {
  useEffect(() => {
    const offcanvasMobileMenu = document.querySelector('#mobile-menu');
    offcanvasMobileMenu.classList.remove('active');
  }, []);
  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector('#mobile-menu');
    offcanvasMobileMenu.classList.add('active');
  };

  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className="topbar__left ml-3">
          <a className="topbar__logo" href="https://nerdflix.io/" />
        </div>
        <nav className="topbar__nav">
          <a className="topbar__nav-link" href="https://nerdflix.io/catalog">
            Aulas
          </a>
          <a className="topbar__nav-link" href="https://nerdflix.io/plan">
            Assinar
          </a>
          <a
            className="topbar__nav-link"
            href="https://nerdflix.io/pages/artigos-cientificos"
          >
            Artigos Científicos
          </a>
          <a
            className="topbar__nav-link"
            href="https://www.certificadosnerdflix.com.br/home"
          >
            Certificados
          </a>
          <a
            className="topbar__nav-link"
            href="https://nerdflix.io/pages/central-de-ajuda"
          >
            Central de Ajuda
          </a>
          <a
            className="topbar__nav-link"
            href="https://nerdflix.io/account/profile"
          >
            Painel
          </a>
          <a className="topbar__nav-link" href="https://nerdflix.io/signout">
            Sair
          </a>
        </nav>
      </div>
      <div className="mobile_topbar_icon d-block d-lg-none">
        <Link
          className="icon-mobile"
          to={`#`}
          onClick={() => triggerMobileMenu()}
        >
          <HamburgerMenuIcon className="ml-2" size="40px" />
        </Link>
      </div>
    </div>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
