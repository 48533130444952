import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap';
import CheckCircleOutlineIcon from 'mdi-react/CheckCircleOutlineIcon';
import TimerSandEmptyIcon from 'mdi-react/TimerSandEmptyIcon';
import CancelIcon from 'mdi-react/CancelIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import { fetchPagamento } from '../../../redux/actions/pagamentosActions';

const DetalhePagamento = ({ props }) => {
  const dispatch = useDispatch();
  const pagamento = useSelector((state) => state.pagamentos.pagamento);
  const isFetching = useSelector((state) => state.pagamentos.isFetching);
  const { id } = props.match.params;

  useEffect(() => {
    dispatch(fetchPagamento(id));
  }, [dispatch, id]);

  return (
    <Col md={12} lg={12}>
      <Card>
        {isFetching && <Spinner className="loading-spinner" />}
        {pagamento &&
          !isFetching &&
          (pagamento?.status === 'Pago' ? (
            <CardBody className="cardbody-confirmacao">
              <CheckCircleOutlineIcon className="icon-confirmacao--success" />
              <h4>Parabéns, sua inscrição foi confirmada!</h4>
              <p>
                Se já estiver preparado para o teste, faça agora mesmo. Caso
                ainda precise estudar alguns pontos, não se preocupe. Você pode
                fazer o teste quando quiser, mas não se esqueça que são 2
                tentativas!
              </p>
              <Row className="buttontoolbar-confirmacao">
                <Link className="btn btn-primary" to={`/home`}>
                  Fazer Teste
                </Link>
              </Row>
            </CardBody>
          ) : pagamento?.status === 'Aguardando' ? (
            <CardBody className="cardbody-confirmacao">
              <TimerSandEmptyIcon className="icon-confirmacao--awaiting" />
              <h4>Você está quase lá. Estamos aguardando seu pagamento...</h4>
              <p>Emita o boleto bancário utilizando o botão abaixo.</p>
              <p>
                Caso já tenha pago o seu boleto, basta aguardar a notificação
                pelo banco. Este processo pode levar até 72hrs.
              </p>
              <p>
                Em caso de dúvidas entre em contato:{' '}
                <a href="mailto:atendimento@paulogentil.com">
                  atendimento@paulogentil.com
                </a>
                .
              </p>
              <Row className="buttontoolbar-confirmacao">
                <a
                  download={`boleto-nerdflix-${pagamento._id}`}
                  className="btn btn-primary"
                  href={`data:application/pdf;base64,${pagamento.log.pdfBoleto}`}
                >
                  Emitir Boleto
                </a>
              </Row>
            </CardBody>
          ) : pagamento?.status === 'Pendente' ? (
            <CardBody className="cardbody-confirmacao">
              <CancelIcon className="icon-confirmacao--pending" />
              <h4>
                A transação de pagamento foi inciada, mas não foi concluída.
              </h4>
              <p>Repita o procedimento para continuar.</p>
              <p>
                Em caso de dúvidas entre em contato:{' '}
                <a href="mailto:atendimento@paulogentil.com">
                  atendimento@paulogentil.com
                </a>
                .
              </p>
              <Row className="buttontoolbar-confirmacao">
                <Link className="btn btn-primary" to="/home">
                  Voltar
                </Link>
              </Row>
            </CardBody>
          ) : pagamento?.status === 'Falhou' ? (
            <CardBody className="cardbody-confirmacao">
              <CloseCircleIcon className="icon-confirmacao--fail" />
              <h4>A transação de pagamento falhou.</h4>
              <p>Detalhe do erro: {JSON.stringify(pagamento.log)}.</p>
              <p>Realize um novo pagamento para continuar.</p>
              <p>
                Em caso de dúvidas entre em contato:{' '}
                <a href="mailto:atendimento@paulogentil.com">
                  atendimento@paulogentil.com
                </a>
                .
              </p>
              <Row className="buttontoolbar-confirmacao">
                <Link className="btn btn-primary" to="/home">
                  Voltar
                </Link>
              </Row>
            </CardBody>
          ) : (
            ''
          ))}
      </Card>
    </Col>
  );
};

export default DetalhePagamento;
