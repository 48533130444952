import React from 'react';
import { render } from 'react-dom';
import { Route } from 'react-router-dom';

function PrivateRoute({ component: Component, roles, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!localStorage.getItem('certificadoNerdflixUser')) {
          // not logged in so redirect to login page with the return url
          return render((window.location.href = 'https://nerdflix.io/'));
        }

        // logged in so return component
        return <Component {...props} />;
      }}
    />
  );
}

export { PrivateRoute };
