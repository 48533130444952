import { createAction } from 'redux-actions';
import pagamentosApi from '../../utils/api/pagamentosApi';

export const fetchPagamentoRequest = createAction('FETCH_PAGAMENTO_REQUEST');
export const fetchPagamentoSuccess = createAction('FETCH_PAGAMENTO_SUCCESS');
export const fetchPagamentoError = createAction('FETCH_PAGAMENTO_ERROR');

export const fetchPagamentosAlunoRequest = createAction(
  'FETCH_PAGAMENTOS_ALUNO_REQUEST'
);
export const fetchPagamentosAlunoSuccess = createAction(
  'FETCH_PAGAMENTOS_ALUNO_SUCCESS'
);
export const fetchPagamentosAlunoError = createAction(
  'FETCH_PAGAMENTOS_ALUNO_ERROR'
);

export const fetchPagamento = (id) => async (dispatch) => {
  try {
    dispatch(fetchPagamentoRequest());
    const { data } = await pagamentosApi.getPagamento(id);
    dispatch(fetchPagamentoSuccess(data));
  } catch (e) {
    console.log(e.response);
    dispatch(fetchPagamentoError(e));
  }
};

export const fetchPagamentosAluno = () => async (dispatch) => {
  try {
    dispatch(fetchPagamentosAlunoRequest());
    const { data } = await pagamentosApi.getPagamentosAluno();
    dispatch(fetchPagamentosAlunoSuccess(data));
  } catch (e) {
    console.log(e.response);
    dispatch(fetchPagamentosAlunoError(e));
  }
};
