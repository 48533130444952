import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row, Button, ButtonGroup } from 'reactstrap';
import CommentQuestionOutlineIcon from 'mdi-react/CommentQuestionOutlineIcon';
import ResultadoFinal from './ResultadoFinal';
import { enviarResultadoTeste } from '../../../redux/actions/inscricoesActions';
import { useTimer } from 'react-timer-hook';

const QuizStart = (props) => {
  const dispatch = useDispatch();
  const { certificado, inscricaoAtual } = props;
  const [step, setStep] = useState(0);
  const [questoes, setQuestoes] = useState([]);
  const [finalizado, setFinalizado] = useState(false);
  const [aprovado, setAprovado] = useState({ value: undefined, nota: 0 });
  const { seconds, minutes, pause, restart } = useTimer({
    onExpire: () => finalizar(),
  });

  useEffect(() => {
    if (certificado.teste) {
      randomicoTeste();
      const tempo = certificado.tempo_prova * 60;
      const time = new Date();
      time.setSeconds(time.getSeconds() + tempo);
      restart(time);
      pause();
    }
  }, [certificado]);

  function init() {
    const tempo = certificado.tempo_prova * 60;
    const time = new Date();
    time.setSeconds(time.getSeconds() + tempo);

    setStep(0);
    randomicoTeste();
    setFinalizado(false);
    restart(time);
    pause();
  }

  function randomicoTeste() {
    if (certificado.teste) {
      let questions = certificado.teste
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);

      for (let indice in questions) {
        const respostas = questions[indice]['respostas']
          .map((value) => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value);

        questions[indice]['respostas'] = respostas;
      }
      setQuestoes(questions);
    }
  }

  function definirResposta(item) {
    let questoesAtuais = JSON.parse(JSON.stringify(questoes));
    questoesAtuais[step - 1]['respostaEscolhida'] = item;
    setQuestoes(questoesAtuais);
  }

  function finalizar() {
    setFinalizado(true);
    props.setShowResult(true);
    let acertos = 0;
    let questoesIds = [];

    for (let indice in questoes) {
      const questao = {
        questaoId: questoes[indice]['numero'],
        respostaId: questoes[indice]['respostaEscolhida']
          ? questoes[indice]['respostaEscolhida']
          : null,
      };
      questoesIds.push(questao);

      acertos =
        questoes[indice]['respostaEscolhida'] &&
        questoes[indice]['respostaEscolhida'] ===
          questoes[indice]['resposta_correta']
          ? acertos + 1
          : acertos;
    }

    const indiceAprovacao = (acertos * 100) / questoes.length;

    let tentativa = {};
    tentativa.data = new Date();
    tentativa.aprovado = false;
    tentativa.percentual = indiceAprovacao.toLocaleString('pt-BR', {
      style: 'decimal',
      maximumFractionDigits: 2,
    });
    tentativa.respostas = questoesIds;

    let status = 'em execução';
    if (indiceAprovacao >= certificado.nota_aprovacao) {
      status = 'concluído';
      tentativa.aprovado = true;
    } else if (
      inscricaoAtual &&
      certificado.tentativas === inscricaoAtual.tentativas.length + 1
    ) {
      status = 'falhou';
    }

    setAprovado({
      value: status,
      nota: indiceAprovacao.toLocaleString('pt-BR', {
        style: 'decimal',
        maximumFractionDigits: 2,
      }),
    });
    dispatch(enviarResultadoTeste(props.inscricaoId, { tentativa, status }));
  }

  return (
    <Fragment>
      {!finalizado ? (
        <div className="Content">
          <div className="typography--inline">
            <Col md={12}>
              <Row className="justify-content-end">
                <div style={{ fontSize: '20px' }}>
                  <h3 className="subhead">
                    {String(minutes).length === 1
                      ? `${minutes}0:`
                      : `${minutes}:`}
                    {String(seconds).length === 1
                      ? `${seconds}0`
                      : `${seconds}`}
                  </h3>
                  <hr />
                </div>
              </Row>
            </Col>
            <Col md={8}>
              <h2>{certificado.titulo}</h2>
            </Col>
            <Col>
              <Row className="justify-content-end">
                <h2 className="subhead">
                  (
                  {certificado.teste
                    ? step === 0
                      ? certificado.teste.length
                      : `${step}/${certificado.teste.length}`
                    : ''}
                  )
                </h2>
              </Row>
            </Col>
          </div>
          <hr />
          {step === 0 ? (
            <>
              <h4 className="subhead typography-message">
                {certificado.descricao}
              </h4>
              <Row className="justify-content-center mt-4">
                <Col md={6}>
                  <ButtonGroup className="btn-group--justified" dir="ltr">
                    <Button
                      color="success"
                      onClick={() => {
                        setStep(1);
                        const tempo = certificado.tempo_prova * 60;
                        const time = new Date();
                        time.setSeconds(time.getSeconds() + tempo);
                        restart(time);
                      }}
                    >
                      Iniciar
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Col md={12}>
                <Row>
                  <h3 className="bold-text mb-4">
                    <CommentQuestionOutlineIcon className="mr-2 ml-4 mt-1" />
                    {questoes[step - 1].descricao}
                  </h3>
                </Row>
              </Col>
              <Col md={12} className="ml-4 mb-4">
                {questoes[step - 1].respostas.map((item, index) => (
                  <Row key={index}>
                    <label>
                      <input
                        id={index}
                        type="radio"
                        className="mr-2"
                        checked={
                          questoes[step - 1]['respostaEscolhida'] &&
                          questoes[step - 1]['respostaEscolhida'] ===
                            item.numero
                            ? true
                            : false
                        }
                        value={item.numero}
                        onChange={() => definirResposta(item.numero)}
                      />
                      <span id={index} className="h6">
                        {item.descricao}
                      </span>
                    </label>
                  </Row>
                ))}
              </Col>
              <Col md={12}>
                <Row>
                  <Col md={6}>
                    <Button
                      outline
                      onClick={() => setStep(step - 1)}
                      disabled={step === 1 ? true : false}
                    >
                      Voltar
                    </Button>
                  </Col>
                  <Col md={6}>
                    {step !== certificado.teste.length ? (
                      <Row className="justify-content-end mr-1">
                        <Button
                          onClick={() => setStep(step + 1)}
                          color="primary"
                        >
                          Próximo
                        </Button>
                      </Row>
                    ) : (
                      <Row className="justify-content-end mr-1">
                        <Button color="danger" onClick={() => finalizar()}>
                          Finalizar
                        </Button>
                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </div>
      ) : (
        <div className="testeFinalizado" hidden={!finalizado}>
          <ResultadoFinal
            aprovado={aprovado}
            tentativas={certificado.tentativas}
            init={init}
            inscricao={props.inscricaoId}
          />
        </div>
      )}
    </Fragment>
  );
};

export default QuizStart;
