import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { fetchCertificado } from '../../../redux/actions/certificadosActions';

const DetalheCertificado = ({ props }) => {
  const dispatch = useDispatch();
  const { id } = props.match.params;
  const certificado = useSelector((state) => state.certificados.certificado);

  useEffect(() => {
    dispatch(fetchCertificado(id));
  }, [dispatch, id]);

  return (
    <Container className="container-lateral">
      <Card>
        <CardBody>
          <Row>
            <Col md={12}>
              <h5 className="bold-text text-uppercase">Certificado</h5>
              <p className="bold-text">{certificado.titulo}</p>
              <p>{certificado.descricao}</p>
              <ul className="list list--icon list--icon-green mt-2">
                <li>
                  <p>
                    <span className="lnr lnr-clock" />
                    Tempo de prova: {certificado.tempo_prova} minutos
                  </p>
                </li>
                <li>
                  <p>
                    <span className="lnr lnr-graduation-hat" />
                    Carga Horária: {certificado.carga_horaria} horas
                  </p>
                </li>
                <li>
                  <p>
                    <span className="lnr lnr-checkmark-circle" />
                    Nota para aprovação: {certificado.nota_aprovacao}%
                  </p>
                </li>
                <li>
                  <p>
                    <span className="lnr lnr-bubble" />
                    Professor: {certificado?.professor?.name}
                  </p>
                </li>
              </ul>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default DetalheCertificado;
