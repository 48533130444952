import React from 'react';
import { Button, ButtonToolbar, Col, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import * as PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import validate from './validate';

const renderField = ({
  input,
  placeholder,
  type,
  mask,
  meta: { touched, error },
}) => (
  <div>
    <MaskedInput {...input} placeholder={placeholder} type={type} mask={mask} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

const renderFieldText = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);
renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  mask: PropTypes.arrayOf(PropTypes.any),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  type: 'text',
  mask: [],
  meta: null,
};

const BoletoBancarioForm = ({ handleSubmit, certificado }) => (
  <form className="form payment__credit-cards" onSubmit={handleSubmit}>
    <input type="hidden" name="forma_pagamento" value="boleto" />
    <div className="form__form-group">
      <Row>
        <Col lg={8}>
          <span className="form__form-group-label">Nome completo</span>
          <div className="form__form-group-field">
            <Field
              name="Holder"
              component={renderFieldText}
              type="text"
              placeholder="Nome e sobrenome"
            />
          </div>
        </Col>
        <Col lg={4}>
          <span className="form__form-group-label">CPF:</span>
          <div className="form__form-group-field">
            <Field
              name="cpf"
              component={renderField}
              type="text"
              mask={[
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
              ]}
              placeholder="xxx.xxx.xxx-xx"
            />
          </div>
        </Col>
      </Row>
    </div>
    <div className="form__form-group">
      <Row>
        <Col lg={4}>
          <span className="form__form-group-label">CEP</span>
          <div className="form__form-group-field">
            <Field
              name="cep"
              component={renderField}
              type="text"
              mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
              placeholder="99.999-999"
            />
          </div>
        </Col>
        <Col lg={8}>
          <span className="form__form-group-label">Endereço</span>
          <div className="form__form-group-field">
            <Field
              name="endereco"
              component={renderFieldText}
              type="text"
              placeholder="Endereço"
            />
          </div>
        </Col>
      </Row>
    </div>
    <div className="form__form-group">
      <Row>
        <Col lg={4}>
          <span className="form__form-group-label">Cidade</span>
          <div className="form__form-group-field">
            <Field
              name="cidade"
              component={renderFieldText}
              type="text"
              placeholder="Cidade"
            />
          </div>
        </Col>
        <Col lg={4}>
          <span className="form__form-group-label">Bairro</span>
          <div className="form__form-group-field">
            <Field
              name="bairro"
              component={renderFieldText}
              type="text"
              placeholder="Bairro"
            />
          </div>
        </Col>
        <Col lg={4}>
          <span className="form__form-group-label">UF</span>
          <div className="form__form-group-field">
            <Field name="uf" component={renderFieldText} type="text" />
          </div>
        </Col>
      </Row>
    </div>

    <h4 className="payment__total">Valor Total: R${certificado.valor}</h4>
    <ButtonToolbar className="form__button-toolbar">
      <Button color="primary" type="submit">
        Realizar Pagamento
      </Button>
    </ButtonToolbar>
  </form>
);

BoletoBancarioForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'payment_form', // a unique identifier for this form
  validate,
})(BoletoBancarioForm);
