import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import SituacaoPagamentoInscricao from './components/DetalhePagamento';

const SituacaoPagamento = (props) => (
  <Container>
    <Row>
      <Col md={12}>
        <h1 className="page-title">Situação do Pagamento</h1>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <SituacaoPagamentoInscricao props={props} />
      </Col>
    </Row>
  </Container>
);

export default SituacaoPagamento;
