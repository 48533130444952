import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reducer as reduxFormReducer } from 'redux-form';
import {
  sidebarReducer,
  themeReducer,
  rtlReducer,
  alunosReducer,
  certificadosReducer,
  cuponsReducer,
  inscricoesReducer,
  pagamentosReducer,
} from '../../redux/reducers/index';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  alunos: alunosReducer,
  certificados: certificadosReducer,
  cupons: cuponsReducer,
  inscricoes: inscricoesReducer,
  pagamentos: pagamentosReducer,
});

const store = createStore(reducer, applyMiddleware(thunk));

export default store;
