import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Button } from 'reactstrap';
import { fetchInscricao } from '../../../redux/actions/inscricoesActions';
import { useDispatch, useSelector } from 'react-redux';

const ResultadoFinal = (props) => {
  const dispatch = useDispatch();
  const inscricaoAtual = useSelector((state) => state.inscricoes.inscricao);
  const { tentativas, aprovado, inscricao } = props;
  useEffect(() => {
    dispatch(fetchInscricao(inscricao));
  }, [dispatch]);

  return (
    <Fragment>
      <h3 className="bold-text">Resultado</h3>
      <hr />
      {aprovado.value === 'concluído' ? (
        <>
          <Row className="justify-content-center">
            <h4 className="typography-message mb-4">
              Parabéns, você passou no teste com aprovação de {aprovado.nota}%
              !!
            </h4>
          </Row>
          <Row className="img-message-quiz justify-content-center mb-5">
            <img
              src={process.env.PUBLIC_URL + '/img/quizMessages/comemoracao.png'}
              alt="Aprovado"
            ></img>
          </Row>
          <Row>
            <Col md={6}>
              <Link to="/home">
                <Button
                  className="mr-5"
                  style={{ width: '50%' }}
                  color="danger"
                >
                  Sair
                </Button>
              </Link>
            </Col>
            <Col md={6}>
              <Row className="justify-content-end mr-1">
                <Link to={`/certificado/${inscricao}`}>
                  <Button
                    color="primary"
                    style={{ width: '100%' }}
                    onClick={() => props.init()}
                  >
                    Emitir Certificado
                  </Button>
                </Link>
              </Row>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row className="justify-content-center">
            <h4 className="typography-message mb-4">
              Sinto muito, você não passou no teste com a percentual de{' '}
              {aprovado.nota}% !
            </h4>
          </Row>
          <Row className="img-message-quiz justify-content-center mb-5">
            <img
              src={process.env.PUBLIC_URL + '/img/quizMessages/fail.png'}
              alt="Reprovado"
            ></img>
          </Row>
          <Row>
            {inscricaoAtual &&
            inscricaoAtual.tentativas &&
            inscricaoAtual.tentativas.length >= tentativas ? (
              <Col md={12}>
                <Link to="/home">
                  <Button color="danger" style={{ width: '100%' }}>
                    Sair
                  </Button>
                </Link>
              </Col>
            ) : (
              <>
                <Col md={6}>
                  <Link to="/home">
                    <Button
                      className="mr-5"
                      style={{ width: '100%' }}
                      color="danger"
                    >
                      Sair
                    </Button>
                  </Link>
                </Col>
                <Col md={6}>
                  <Row className="justify-content-center">
                    <Button
                      color="warning"
                      style={{ width: '100%' }}
                      onClick={() => props.init()}
                    >
                      Tentar Novamente
                    </Button>
                  </Row>
                </Col>
              </>
            )}
          </Row>
        </>
      )}
    </Fragment>
  );
};

export default ResultadoFinal;
