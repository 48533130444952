import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Card, CardBody, Col, Spinner } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import CartaoCreditoForm from './CartaoCredito/CartaoCreditoForm';
import BoletoBancarioForm from './BoletoBancario/BoletoBancarioForm';
import { fetchCertificado } from '../../../redux/actions/certificadosActions';
import {
  novaInscricaoCartaoCredito,
  novaInscricaoBoletoBancario,
  novaInscricaoCupom,
} from '../../../redux/actions/inscricoesActions';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import Alert from '../../../shared/components/Alert';
import CupomForm from './Cupom/CupomForm';

const Cupom = `${process.env.PUBLIC_URL}/img/cards/cupom.svg`;
const CartaoCredito = `${process.env.PUBLIC_URL}/img/cards/cartao.svg`;
const BoletoBancario = `${process.env.PUBLIC_URL}/img/cards/boleto.svg`;
const Pix = `${process.env.PUBLIC_URL}/img/cards/pix.svg`;

const PagamentoInscricao = ({ props }) => {
  const dispatch = useDispatch();
  const [isRedirect, setIsRedirect] = useState(false);
  const [formaPagamento, setformaPagamento] = useState('cartaocredito');
  const certificado = useSelector((state) => state.certificados.certificado);
  const isFetching = useSelector((state) => state.certificados.isFetching);
  const isProcessing = useSelector((state) => state.inscricoes.isProcessing);
  const error = useSelector((state) => state.inscricoes.error);
  const inscricao = useSelector((state) => state.inscricoes.inscricao);
  const { id } = props.match.params;

  useEffect(() => {
    dispatch(fetchCertificado(id));
  }, [dispatch, id]);

  const onSubmitCartaoCredito = (data) => {
    const pagamento = {
      forma_pagamento: 'cartaocredito',
    };
    const dataArray = data.ExpirationDate.split('/');
    const dataCompleta = `${dataArray[0]}/20${dataArray[1]}`;
    const numeroCartao = data.CardNumber.replaceAll('-', '');
    const creditcard = {
      CardNumber: numeroCartao,
      Holder: data.Holder,
      ExpirationDate: dataCompleta,
      SecurityCode: data.SecurityCode,
      Brand: 'visa',
    };
    pagamento.creditcard = creditcard;
    dispatch(novaInscricaoCartaoCredito(id, pagamento));
    setIsRedirect(true);
  };

  const onSubmitBoletoBancario = (data) => {
    const pagamento = {
      forma_pagamento: 'boletobancario',
    };
    const cpf = data.cpf.replaceAll('-', '').replaceAll('.', '');
    const cep = data.cep.replaceAll('-', '').replaceAll('.', '');
    const boletobancario = {
      cpf: cpf,
      cep: cep,
      uf: data.uf,
      cidade: data.cidade,
      bairro: data.bairro,
      endereco: data.endereco,
    };
    pagamento.boletobancario = boletobancario;
    dispatch(novaInscricaoBoletoBancario(id, pagamento));
    setIsRedirect(true);
  };

  const onSubmitCupom = (data) => {
    const pagamento = {
      forma_pagamento: 'cupom',
      codigo: data.codigo,
    };
    dispatch(novaInscricaoCupom(id, pagamento));
    setIsRedirect(true);
  };

  const handleChange = (event) => {
    setformaPagamento(event);
  };

  if (isRedirect && !isFetching && !isProcessing && inscricao && !error) {
    return <Redirect to={`/pagamento/${inscricao?.pagamento?._id}`} />;
  }

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Pagamento</h5>
          </div>
          {!isFetching && error && (
            <Alert color="danger">
              <p>
                {`Não foi possível efetuar o pagamento. Status de erro: ${error}`}
              </p>
            </Alert>
          )}
          {(isFetching || isProcessing) && (
            <Spinner className="loading-spinner" />
          )}
          {certificado && !isFetching && !isProcessing && (
            <div className="payment">
              <form className="form payment__credit-cards">
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Escolha uma forma de pagamento:
                  </span>
                  <div className="form__form-group-field">
                    <div className="payment__credit-card">
                      <Field
                        name="forma_pagamento"
                        component={renderRadioButtonField}
                        onChange={handleChange}
                        // eslint-disable-next-line
                        label={
                          <div>
                            <img
                              className="payment__credit-card_img"
                              src={Cupom}
                              alt="cupom"
                            />
                            <p className="payment__credit-name">Cupom</p>
                          </div>
                        }
                        radioValue="cupom"
                        defaultChecked
                      />
                    </div>
                    <div className="payment__credit-card">
                      <Field
                        name="forma_pagamento"
                        component={renderRadioButtonField}
                        onChange={handleChange}
                        // eslint-disable-next-line
                        label={
                          <div>
                            <img
                              className="payment__credit-card_img"
                              src={CartaoCredito}
                              alt="mc"
                            />
                            <p className="payment__credit-name">
                              Cartão de crédito
                            </p>
                          </div>
                        }
                        radioValue="cartaocredito"
                      />
                    </div>
                    <div
                      className="payment__credit-card"
                      style={{ display: 'none' }}
                    >
                      <Field
                        name="forma_pagamento"
                        component={renderRadioButtonField}
                        onChange={handleChange}
                        // eslint-disable-next-line
                        label={
                          <div>
                            <img
                              className="payment__credit-card_img"
                              src={BoletoBancario}
                              alt="visa"
                            />
                            <p className="payment__credit-name">
                              Boleto Bancário
                            </p>
                          </div>
                        }
                        radioValue="boleto"
                      />
                    </div>
                    <div
                      className="payment__credit-card"
                      style={{ display: 'none' }}
                    >
                      <Field
                        name="forma_pagamento"
                        component={renderRadioButtonField}
                        onChange={handleChange}
                        // eslint-disable-next-line
                        label={
                          <div>
                            <img
                              className="payment__credit-card_img"
                              src={Pix}
                              alt="paypal"
                            />
                            <p className="payment__credit-name">Pix</p>
                          </div>
                        }
                        radioValue="pix"
                      />
                    </div>
                  </div>
                </div>
              </form>
              {formaPagamento === 'cartaocredito' ? (
                <CartaoCreditoForm
                  onSubmit={onSubmitCartaoCredito}
                  certificado={certificado}
                />
              ) : formaPagamento === 'boleto' ? (
                <BoletoBancarioForm
                  onSubmit={onSubmitBoletoBancario}
                  certificado={certificado}
                />
              ) : formaPagamento === 'cupom' ? (
                <CupomForm onSubmit={onSubmitCupom} certificado={certificado} />
              ) : (
                ''
              )}
            </div>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};
export default reduxForm({
  form: 'metodopagamento', // a unique identifier for this form
})(PagamentoInscricao);
