import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, Container, Row, Spinner } from 'reactstrap';
import { fetchInscricao } from '../../../redux/actions/inscricoesActions';

const DetalheInscricao = ({ props }) => {
  const dispatch = useDispatch();
  const { id } = props.match.params;
  const inscricao = useSelector((state) => state.inscricoes.inscricao);
  const { certificado } = inscricao;
  const isFetching = useSelector((state) => state.inscricoes.isFetching);

  useEffect(() => {
    dispatch(fetchInscricao(id));
  }, [dispatch, id]);

  return (
    <Container className="container-lateral">
      <Card>
        <CardBody>
          <Row>
            {isFetching && <Spinner className="loading-spinner" />}
            {certificado && (
              <Col md={12}>
                <h5 className="bold-text text-uppercase">Aluno</h5>
                <p>{inscricao?.aluno?.name}</p>
                <h5 className="bold-text text-uppercase mt-2">Certificado</h5>
                <p className="bold-text">{certificado?.titulo}</p>
                <p>{certificado?.descricao}</p>
                <ul className="list list--icon list--icon-green mt-2">
                  <li>
                    <p>
                      <span className="lnr lnr-clock" />
                      Tempo de prova: {certificado?.tempo_prova} minutos
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className="lnr lnr-graduation-hat" />
                      Carga Horária: {certificado?.carga_horaria} horas
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className="lnr lnr-checkmark-circle" />
                      Nota para aprovação: {certificado?.nota_aprovacao}%
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className="lnr lnr-bubble" />
                      Professor: {certificado?.professor?.name}
                    </p>
                  </li>
                </ul>
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default DetalheInscricao;
