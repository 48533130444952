import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import { Link } from 'react-router-dom';
import { fetchPdfCertificado } from '../../../redux/actions/inscricoesActions';
import DetalheInscricao from '../../Comuns/DetalheInscricao';
import DownloadIcon from 'mdi-react/DownloadIcon';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CertificadoGerado = ({ props }) => {
  const dispatch = useDispatch();
  const { id } = props.match.params;
  const pdfCertificado = useSelector(
    (state) => state.inscricoes.pdfCertificado
  );
  const isFetching = useSelector((state) => state.inscricoes.isFetching);

  useEffect(() => {
    dispatch(fetchPdfCertificado(id));
  }, [dispatch, id]);

  return (
    <Row>
      <Col lg={{ span: 8, order: 1 }} md={8} xs={{ span: 12, order: 2 }}>
        <Card>
          <CardBody>
            {!isFetching && (
              <Document
                file={`data:application/pdf;base64,${pdfCertificado?.pdf}`}
              >
                <Page pageNumber={1} />
              </Document>
            )}
          </CardBody>
        </Card>
      </Col>
      <Col
        lg={{ span: 4, order: 2 }}
        md={4}
        xs={{ span: 12, order: 1 }}
        className="mb-4"
      >
        <DetalheInscricao props={props} />
        <Row className="justify-content-center mt-3">
          <a
            download={`certificado-nerdflix-${id}`}
            className="btn btn-primary px-5"
            href={`data:application/pdf;base64,${pdfCertificado?.pdf}`}
          >
            <DownloadIcon className="mr-2" />
            BAIXAR
          </a>
          <Link className="btn btn-secondary px-5" to="/">
            <ArrowLeftIcon className="mr-2" /> VOLTAR
          </Link>
        </Row>
      </Col>
    </Row>
  );
};

export default CertificadoGerado;
