import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';

import PlaylistCheckIcon from 'mdi-react/PlaylistCheckIcon';
import FormatListChecksIcon from 'mdi-react/FormatListChecksIcon';
import CertificateIcon from 'mdi-react/CertificateIcon';
import CloseOctagonOutlineIcon from 'mdi-react/CloseOctagonOutlineIcon';

const ItemCertificado = ({ titulo, descricao, status, id, inscricaoId }) => {
  return (
    <div className="project-member">
      <Col sm={10}>
        <div className="pr-5">
          <p className="project-member__name">{titulo}</p>
          <p className="project-member__post">{descricao}</p>
        </div>
      </Col>
      {status === 'publicado' ? (
        <Link
          className="project-member__btn btn btn-primary btn-sm icon"
          to={`inscricao/${id}`}
        >
          Inscreva-se
          <PlaylistCheckIcon className="ml-2" />
        </Link>
      ) : status === 'inscrito' ? (
        <Link
          className="project-member__btn btn btn-secondary btn-sm icon"
          to={{
            pathname: `quiz/${id}`,
            state: {
              inscricaoId: inscricaoId,
            },
          }}
        >
          Começar
          <FormatListChecksIcon className="ml-2" />
        </Link>
      ) : status === 'em execução' ? (
        <Link
          className="project-member__btn btn btn-warning btn-sm icon"
          to={{
            pathname: `quiz/${id}`,
            state: {
              inscricaoId: inscricaoId,
            },
          }}
        >
          Refazer
          <FormatListChecksIcon className="ml-2" />
        </Link>
      ) : status === 'concluído' ? (
        <Link
          className="project-member__btn btn btn-success btn-sm icon"
          to={`/certificado/${inscricaoId}`}
        >
          Sucesso
          <CertificateIcon className="ml-2" />
        </Link>
      ) : status === 'falhou' ? (
        <Link
          className="project-member__btn btn btn-danger btn-sm icon"
          to={`situacao-inscricao/${inscricaoId}`}
        >
          Falhou
          <CloseOctagonOutlineIcon className="ml-2" />
        </Link>
      ) : (
        status
      )}
    </div>
  );
};

ItemCertificado.propTypes = {
  titulo: PropTypes.string.isRequired,
  descricao: PropTypes.string,
  status: PropTypes.string.isRequired,
};

ItemCertificado.defaultProps = {
  descricao: '',
};

export default ItemCertificado;
