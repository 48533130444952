import { handleActions } from 'redux-actions';
import {
  fetchPagamentoRequest,
  fetchPagamentoSuccess,
  fetchPagamentoError,
  fetchPagamentosAlunoRequest,
  fetchPagamentosAlunoSuccess,
  fetchPagamentosAlunoError,
} from '../actions/pagamentosActions';

const defaultState = {
  pagamento: {},
  pagamentos: {},
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [fetchPagamentoRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchPagamentoSuccess](state, { payload }) {
      return {
        ...state,
        pagamento: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchPagamentoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchPagamentosAlunoRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchPagamentosAlunoSuccess](state, { payload }) {
      return {
        ...state,
        pagamentos: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchPagamentosAlunoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
