export const API = process.env.REACT_APP_API_URL;

export const getOidcStorageKey = () => {
  const authSettings = JSON.parse(localStorage.getItem('authSettings'));
  if (authSettings) {
    return `oidc.user:${authSettings.auth_server}:${authSettings.client_id}`;
  }
  return null;
};

export const getOidcInfo = () => {
  const key = getOidcStorageKey();
  if (key) {
    const oidc = JSON.parse(localStorage.getItem(key));
    return oidc;
  }
  return null;
};

export const getToken = () => {
  const user = JSON.parse(localStorage.getItem('certificadoNerdflixUser'));

  if (user) {
    return user.token;
  } else {
    return '';
  }
};

export const getUserId = () => {
  const { aluno } = JSON.parse(localStorage.getItem('certificadoNerdflixUser'));

  if (aluno) {
    return aluno._id;
  } else {
    return '';
  }
};
