import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Card, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import EyeIcon from 'mdi-react/EyeIcon';
import moment from 'moment';
import TimerSandEmptyIcon from 'mdi-react/TimerSandEmptyIcon';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import { fetchPagamentosAluno } from '../../../redux/actions/pagamentosActions';

const imagensPagamento = {
  cupom: `${process.env.PUBLIC_URL}/img/cards/cupom.svg`,
  cartaocredito: `${process.env.PUBLIC_URL}/img/cards/cartao.svg`,
  boletobancario: `${process.env.PUBLIC_URL}/img/cards/boleto.svg`,
  pix: `${process.env.PUBLIC_URL}/img/cards/pix.svg`,
};

const ListaPagamentos = ({ props }) => {
  const dispatch = useDispatch();
  const pagamentos = useSelector((state) => state.pagamentos.pagamentos);
  const isFetching = useSelector((state) => state.pagamentos.isFetching);

  const colunas = useMemo(
    () => [
      {
        Header: 'DATA',
        accessor: 'data',
        disableGlobalFilter: true,
        width: 250,
      },
      {
        Header: 'CERTIFICADO',
        accessor: 'certificadoTitulo',
        disableGlobalFilter: true,
        width: 200,
      },
      {
        Header: 'VALOR',
        accessor: 'valor',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'FORMA DE PAGAMENTO',
        accessor: 'forma_pagamento',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'SITUAÇÃO',
        accessor: 'status',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: '',
        accessor: 'acao',
        disableGlobalFilter: true,
        width: 60,
      },
    ],
    []
  );

  const dadosTabela = (pagamentos) => {
    moment.locale('pt-br');
    return pagamentos.map((pagamento) => ({
      data: moment(pagamento.createdAt).format('DD/MM/YYYY HH:mm'),
      certificadoTitulo: pagamento.certificado?.titulo,
      valor: `R$ ${pagamento.valor},00`,
      forma_pagamento: (
        <img
          className="payment__credit-card_img"
          src={imagensPagamento[pagamento.forma_pagamento]}
          alt="visa"
        />
      ),
      status: (
        <Badge className={`badge--${pagamento.status}`}>
          {pagamento.status}
        </Badge>
      ),
      acao: (
        <Link
          color="primary"
          className="btn btn-outline-secondary btn-sm list__table-btn-action mb-0 px-2"
          to={`/pagamento/${pagamento._id}`}
        >
          <EyeIcon /> Ver detalhes
        </Link>
      ),
    }));
  };

  useEffect(() => {
    dispatch(fetchPagamentosAluno());
  }, [dispatch]);

  return (
    <Card>
      {isFetching && <Spinner className="loading-spinner" />}
      {pagamentos && pagamentos.length > 0 && !isFetching && (
        <ReactTableBase
          key={'common'}
          columns={colunas}
          data={dadosTabela(pagamentos)}
          tableConfig={{
            isEditable: false,
            isResizable: false,
            isSortable: false,
            withDragAndDrop: false,
            withPagination: true,
            withSearchEngine: false,
            manualPageSize: [10, 20, 30, 40],
            placeholder: 'Search by First name...',
          }}
        />
      )}
      {pagamentos && pagamentos.length === 0 && !isFetching && (
        <div className="text-center my-5">
          <p>
            <TimerSandEmptyIcon className="icon-confirmacao--awaiting" />
            <h4>Nada aqui por enquanto...</h4>
          </p>
        </div>
      )}
    </Card>
  );
};

export default ListaPagamentos;
