import React from 'react';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';

const TopbarMobile = () => {
  const closeMenu = () => {
    const offcanvasMobileMenu = document.querySelector('#mobile-menu');
    offcanvasMobileMenu.classList.remove('active');
  };
  return (
    <div className="mobile-menu d-block d-lg-none" id="mobile-menu">
      <div className="offcanvas-wrapper">
        <div className="offcanvas-inner-content">
          <nav>
            <a
              className="topbar_mobile__nav-link center"
              onClick={() => closeMenu()}
              href="#close"
            >
              <CloseCircleOutlineIcon className="ml-2" size="38px" />
            </a>
            <a
              className="topbar_mobile__nav-link"
              href="https://nerdflix.io/catalog"
            >
              Aulas
            </a>
            <a
              className="topbar_mobile__nav-link"
              href="https://nerdflix.io/orders/customer_info?o=25867"
            >
              Assinar
            </a>
            <a
              className="topbar_mobile__nav-link"
              href="https://nerdflix.io/pages/artigos-cientificos"
            >
              Artigos Científicos
            </a>
            <a
              className="topbar_mobile__nav-link"
              href="https://www.certificadosnerdflix.com.br/home"
            >
              Certificados
            </a>
            <a
              className="topbar_mobile__nav-link"
              href="https://nerdflix.io/pages/central-de-ajuda"
            >
              Central de Ajuda
            </a>
            <a
              className="topbar_mobile__nav-link"
              href="https://nerdflix.io/dashboard"
            >
              Painel
            </a>
            <a
              className="topbar_mobile__nav-link"
              href="https://nerdflix.io/sign_out"
            >
              Sair
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default TopbarMobile;
