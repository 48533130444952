import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ListaPagamentos from './components/ListaPagamentos';

const Pagamentos = (props) => (
  <Container>
    <Row>
      <Col md={12}>
        <h1 className="page-title">Pagamentos</h1>
        <h3 className="page-subhead subhead">
          Verifique a situação de seus pagamentos por{' '}
          <strong>CERTIFICADOS</strong>. Os pagamentos das mensalidades do
          Nerdflix ficam disponíveis no PAINEL da plataforma.
        </h3>
      </Col>
    </Row>
    <Row>
      <Col xl={9}>
        <ListaPagamentos props={props} />
      </Col>
    </Row>
  </Container>
);

export default Pagamentos;
