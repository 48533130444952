/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.CardNumber) {
    errors.CardNumber = 'Número do cartão não pode ser vazio';
  }
  if (!values.ExpirationDate) {
    errors.ExpirationDate = 'Data de validade não pode ser vazio';
  }
  if (!values.Holder) {
    errors.Holder = 'Nome impresso no cartão não pode ser vazio';
  }
  if (!values.SecurityCode) {
    errors.SecurityCode = 'CVV do cartão não pode ser vazio';
  }
  return errors;
};

export default validate;
