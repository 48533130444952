import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Topbar from './topbar/Topbar';
import {
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
} from '../../redux/actions/sidebarActions';
import { SidebarProps } from '../../shared/prop-types/ReducerProps';
import TopbarMobile from './topbar/TopbarMobile';

const Layout = ({ dispatch, sidebar }) => {
  const layoutClass = classNames({
    layout: true,
    'layout--collapse': true,
  });

  const sidebarVisibility = () => {
    dispatch(changeSidebarVisibility());
  };

  const mobileSidebarVisibility = () => {
    dispatch(changeMobileSidebarVisibility());
  };

  return (
    <div className={layoutClass}>
      <Topbar
        changeMobileSidebarVisibility={mobileSidebarVisibility}
        changeSidebarVisibility={sidebarVisibility}
      />
      <TopbarMobile />
    </div>
  );
};

Layout.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sidebar: SidebarProps.isRequired,
};

export default withRouter(
  connect((state) => ({
    sidebar: state.sidebar,
  }))(Layout)
);
