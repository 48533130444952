import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import DetalheInscricao from './components/DetalheInscricao';

const SituacaoInscricao = (props) => (
  <Container>
    <Row>
      <Col md={12}>
        <h1 className="page-title">Detalhes da Inscrição</h1>
        <h3 className="page-subhead subhead">
          Verifique as situação da sua inscrição e seus pagamentos.
        </h3>
      </Col>
    </Row>
    <Row>
      <Col md={8}>
        <DetalheInscricao props={props} />
      </Col>
    </Row>
  </Container>
);

export default SituacaoInscricao;
