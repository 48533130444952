import { handleActions } from 'redux-actions';
import {
  fetchInscricaoRequest,
  fetchInscricaoSuccess,
  fetchInscricaoError,
  fetchInscricoesEmAndamentoRequest,
  fetchInscricoesEmAndamentoSuccess,
  fetchInscricoesEmAndamentoError,
  fetchInscricoesConcluidasRequest,
  fetchInscricoesConcluidasSuccess,
  fetchInscricoesConcluidasError,
  novaInscricaoCartaoCreditoRequest,
  novaInscricaoCartaoCreditoSuccess,
  novaInscricaoCartaoCreditoError,
  novaInscricaoBoletoBancarioRequest,
  novaInscricaoBoletoBancarioSuccess,
  novaInscricaoBoletoBancarioError,
  novaInscricaoCupomRequest,
  novaInscricaoCupomSuccess,
  novaInscricaoCupomError,
  testeEnviadoSuccess,
  testeEnviadoError,
  fetchPdfCertificadoRequest,
  fetchPdfCertificadoSuccess,
  fetchPdfCertificadoError,
} from '../actions/inscricoesActions';

const defaultState = {
  inscricao: {},
  inscricoesEmAndamento: [],
  inscricoesConcluidas: [],
  pdfCertificado: {},
  isFetching: false,
  isProcessing: false,
  error: null,
};

export default handleActions(
  {
    [fetchInscricaoRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchInscricaoSuccess](state, { payload }) {
      return {
        ...state,
        inscricao: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchInscricaoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchInscricoesEmAndamentoRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchInscricoesEmAndamentoSuccess](state, { payload }) {
      return {
        ...state,
        inscricoesEmAndamento: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchInscricoesEmAndamentoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchInscricoesConcluidasRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchInscricoesConcluidasSuccess](state, { payload }) {
      return {
        ...state,
        inscricoesConcluidas: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchInscricoesConcluidasError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [novaInscricaoCartaoCreditoRequest](state) {
      return {
        ...state,
        isProcessing: true,
        error: null,
      };
    },
    [novaInscricaoCartaoCreditoSuccess](state, { payload }) {
      return {
        ...state,
        inscricao: payload,
        isProcessing: false,
        error: null,
      };
    },
    [novaInscricaoCartaoCreditoError](state, { payload }) {
      return {
        ...state,
        isProcessing: false,
        error: payload,
      };
    },
    [novaInscricaoBoletoBancarioRequest](state) {
      return {
        ...state,
        isProcessing: true,
        error: null,
      };
    },
    [novaInscricaoBoletoBancarioSuccess](state, { payload }) {
      return {
        ...state,
        inscricao: payload,
        isProcessing: false,
        error: null,
      };
    },
    [novaInscricaoBoletoBancarioError](state, { payload }) {
      return {
        ...state,
        isProcessing: false,
        error: payload,
      };
    },
    [novaInscricaoCupomRequest](state) {
      return {
        ...state,
        isProcessing: true,
        error: null,
      };
    },
    [novaInscricaoCupomSuccess](state, { payload }) {
      return {
        ...state,
        inscricao: payload,
        isProcessing: false,
        error: null,
      };
    },
    [novaInscricaoCupomError](state, { payload }) {
      return {
        ...state,
        isProcessing: false,
        error: payload,
      };
    },
    [testeEnviadoSuccess](state, { payload }) {
      return {
        ...state,
        inscricao: payload,
        isFetching: false,
        error: null,
      };
    },
    [testeEnviadoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchPdfCertificadoRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchPdfCertificadoSuccess](state, { payload }) {
      return {
        ...state,
        pdfCertificado: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchPdfCertificadoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
