import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Card, CardBody, Col, Spinner, Table } from 'reactstrap';
import { fetchInscricao } from '../../../redux/actions/inscricoesActions';

const DetalheInscricao = ({ props }) => {
  const dispatch = useDispatch();
  const inscricao = useSelector((state) => state.inscricoes.inscricao);
  const isFetching = useSelector((state) => state.inscricoes.isFetching);
  const { id } = props.match.params;

  useEffect(() => {
    dispatch(fetchInscricao(id));
  }, [dispatch, id]);

  return (
    <Col md={12} lg={12}>
      <Card>
        {isFetching && <Spinner className="loading-spinner" />}
        {inscricao && !isFetching && (
          <CardBody className="invoice">
            <div className="invoice__head">
              <div className="invoice__head-left">
                <div className="invoice__logo" />
                <p>Advanced Digital - Nerdflix</p>
                <p>QS 01 RUA 210, LOTE 40, Sala 214</p>
                <p>Torre A - Taguatinga Shopping,</p>
                <p>Brasília - DF,</p>
                <p>71950-904</p>
                <p>(61) 99904-9526</p>
              </div>
              <div className="invoice__head-right">
                <h4>Inscrição #{inscricao?._id}</h4>
                <p>
                  <Badge color="success">{inscricao?.pagamento?.status}</Badge>
                </p>
                <p className="invoice__date">{inscricao?.createdAt}</p>
                <p>{inscricao?.aluno?.name}</p>
                <p>{inscricao?.aluno?.email}</p>
              </div>
            </div>
            <Table className="table--bordered" responsive>
              <thead>
                <tr>
                  <th>Certificado</th>
                  <th>Carga horária</th>
                  <th>Nota de aprovação</th>
                  <th>Tempo de prova</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{inscricao?.certificado?.titulo}</td>
                  <td>{inscricao?.certificado?.carga_horaria}hs</td>
                  <td>{inscricao?.certificado?.nota_aprovacao}%</td>
                  <td>{inscricao?.certificado?.tempo_prova}min.</td>
                </tr>
              </tbody>
            </Table>
            <div className="invoice__total">
              <p className="invoice__grand-total">
                Total: R${inscricao?.pagamento?.valor}
              </p>
            </div>
          </CardBody>
        )}
      </Card>
    </Col>
  );
};

export default DetalheInscricao;
