import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import CertificadoGerado from './Components/Certificado';

const Certificado = (props) => {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <h1 className="page-title">Emitir Certificado</h1>
            </Col>
          </Row>
        </Col>
      </Row>

      <CertificadoGerado props={props} />
    </Container>
  );
};

export default Certificado;
