import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonToolbar, Col, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import * as PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import validate from './validate';
import { validarCupom } from '../../../../redux/actions/cuponsActions';

const renderField = ({
  input,
  placeholder,
  type,
  mask,
  meta: { touched, error },
}) => (
  <div>
    <MaskedInput {...input} placeholder={placeholder} type={type} mask={mask} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

const renderFieldText = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);
renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  mask: PropTypes.arrayOf(PropTypes.any),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  type: 'text',
  mask: [],
  meta: null,
};

const CupomForm = ({ handleSubmit, certificado }) => {
  const dispatch = useDispatch();
  const cupom = useSelector((state) => state.cupons.cupom);
  const error = useSelector((state) => state.cupons.error);

  const [codigo, setCodigo] = useState('');
  const [valor, setValor] = useState(certificado.valor);
  const [valorDesconto, setValorDesconto] = useState(0);

  const validarCupomHandle = () => {
    dispatch(validarCupom(codigo));
  };

  useEffect(() => {
    setValorDesconto(0);
  }, [codigo]);

  useEffect(() => {
    let desconto = 0;

    if (cupom.tipo_desconto === 'percentual') {
      desconto = (valor * cupom.valor_desconto) / 100;
    }

    if (cupom.tipo_desconto === 'valor') {
      desconto = cupom.valor_desconto;
    }
    setValorDesconto(desconto);
  }, [cupom]);

  useEffect(() => {
    setValor(certificado.valor - valorDesconto);
  }, [cupom, valorDesconto, certificado.valor]);

  return (
    <form className="form payment__credit-cards" onSubmit={handleSubmit}>
      <input type="hidden" name="forma_pagamento" value="cupom" />
      <div className="form__form-group">
        <Row>
          <Col lg={4}>
            <span className="form__form-group-label">Código do cupom</span>
            <div className="form__form-group-field">
              <Field
                name="codigo"
                component={renderFieldText}
                type="text"
                placeholder="código do cupom"
                onChange={(e) => setCodigo(e.target.value)}
              />
            </div>
            <span className="form__form-group-error">{error}</span>
          </Col>
          <Col lg={4} className="align-text-bottom">
            <span className="form__form-group-label">&nbsp;</span>
            <div className="form__form-group-field">
              <Button
                color="secondary"
                className="py-1"
                onClick={(e, data) => validarCupomHandle(data)}
              >
                Aplicar
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      <h4 className="payment__total">
        {`Valor Total: R$${valor}`}
        {valorDesconto > 0 && (
          <span className="form__form-group-message">{`Desconto de R$${valorDesconto}`}</span>
        )}
      </h4>
      <ButtonToolbar className="form__button-toolbar">
        <Button color="primary" type="submit">
          Realizar Pagamento
        </Button>
      </ButtonToolbar>
    </form>
  );
};

CupomForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'payment_form', // a unique identifier for this form
  validate,
})(CupomForm);
